<template>
	<div>
		<AuthHeader>
			<a @click="goto('signin')" class="btn btn-primary btn-standart-2 ml-20" style="cursor: pointer">Login</a>
			<a @click="goto('signup')" class="btn btn-primary btn-standart-2 ml-10" style="cursor: pointer">Create an Account</a> 
		</AuthHeader>

		<div class="wrapper_main none-padding wrapper_legal">
			<main class="content content-center">
				<div class="container">
					<div class="legal-wrapper" v-if="this.$route.name === 'Terms'">
						<h1>TERMS OF USE</h1>
						<div class="legal-text">
							<h3>AGREEMENT TO TERMS</h3>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt nemo quo repudiandae iste. Corporis perferendis magnam, repellat amet ipsam, consequatur corrupti aliquid eum quasi voluptate laudantium harum unde iusto totam?
								Hic quae quisquam laboriosam commodi possimus? Atque voluptas reiciendis dolores, ullam cupiditate voluptates voluptatibus consequatur sed laborum saepe soluta laudantium! Aliquam est, pariatur voluptatem distinctio molestias quibusdam nam ad expedita!
								Sunt sequi facilis facere? Similique in laboriosam quis, eveniet, facilis at, laudantium maiores temporibus est repudiandae tenetur velit dignissimos quas excepturi nulla blanditiis saepe nam ipsa! Quidem accusamus porro at.
							</p>
							<h3>Use of License</h3>
							<p>
								Recusandae deserunt maiores temporibus et beatae reiciendis amet possimus reprehenderit, at accusamus, sunt voluptates velit architecto cum eaque vero dolorum impedit a aperiam? Dolorem, exercitationem! Error numquam ducimus consequuntur nam!
								Explicabo consequatur, iure aspernatur provident suscipit nostrum pariatur? Quod dolore quibusdam enim. Autem sunt est, fugit adipisci quae, quod laboriosam eum exercitationem dolorum non optio similique nihil ipsam, consectetur aut?
								Vel cum odio doloremque nostrum voluptas dolor fuga reprehenderit vero ipsa! Aliquam accusamus sequi inventore tempora, eveniet repellat debitis laboriosam sint quibusdam labore. Explicabo nulla sed corporis quisquam, a facere!
							</p>
							<ul>
								<li>
									Lorem ipsum dolor
								</li>
								<li>
									Sit amet consectetur adipisicing elit.
								</li>
							</ul>
							<h3>Services and Data</h3>
							<p>
								Alias, culpa asperiores explicabo minima quam, vitae, similique cumque repellat officia quia nobis ullam? Dolorem, illo voluptatum voluptas veritatis exercitationem vero autem similique commodi odio labore voluptates porro esse eius!
								Perspiciatis rerum ea blanditiis corrupti vel, dignissimos pariatur perferendis ad laudantium velit tempora minima assumenda sapiente? Suscipit impedit consequatur fugiat ducimus cum error nesciunt, quia harum perferendis ab odio pariatur!
							</p>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione animi cum excepturi eveniet dicta laboriosam provident quas quae unde quibusdam, asperiores architecto, ad ex quisquam ipsam earum repudiandae aspernatur! Eaque!
							</p>

							<h3>AGREEMENT TO TERMS</h3>
							<p>These Terms of Use constitute a legally binding agreement made between you, whether
							personally or on behalf of an entity (“you”) and Enismaro S.r.l. ("Company", “we”, “us”, or
							“our”), concerning your access to and use of the http://www.enismaro.com website as well
							as any other media form, media channel, mobile website or mobile application related,
							linked, or otherwise connected thereto (collectively, the “Site”). We are registered in Italy
							and have our registered office at Via Casarse, 12, Salerno, SA 84133. Our VAT number is
							05969710655. You agree that by accessing the Site, you have read, understood, and
							agree to be bound by all of these Terms of Use.
							IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
							EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE
							USE IMMEDIATELY.</p>
							<p>Supplemental terms and conditions or documents that may be posted on the Site from
							time to time are hereby expressly incorporated herein by reference. We reserve the right,
							in our sole discretion, to make changes or modifications to these Terms of Use from time
							to time. We will alert you about any changes by updating the “Last updated” date of these
							Terms of Use, and you waive any right to receive specific notice of each such change.
							Please ensure that you check the applicable Terms every time you use our Site so that
							you understand which Terms apply. You will be subject to, and will be deemed to have
							been made aware of and to have accepted, the changes in any revised Terms of Use by
							your continued use of the Site after the date such revised Terms of Use are posted.
							The information provided on the Site is not intended for distribution to or use by any
							person or entity in any jurisdiction or country where such distribution or use would be
							contrary to law or regulation or which would subject us to any registration requirement
							within such jurisdiction or country. Accordingly, those persons who choose to access the
							Site from other locations do so on their own initiative and are solely responsible for
							compliance with local laws, if and to the extent local laws are applicable.
							The Site is not tailored to comply with industry-specific regulations (Health Insurance
							Portability and Accountability Act (HIPAA), Federal Information Security Management Act
							(FISMA), etc.), so if your interactions would be subjected to such laws, you may not use
							this Site. You may not use the Site in a way that would violate the Gramm- Leach-Bliley
							Act (GLBA).</p>
							<p>The Site is intended for users who are at least 18 years old. Persons under the age of 18
							are not permitted to use or register for the Site.</p>

							<h3>INTELLECTUAL PROPERTY RIGHTS</h3>
							<p>Unless otherwise indicated, the Site is our proprietary property and all source code,
							databases, functionality, software, website designs, audio, video, text, photographs, and
							graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and
							logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and
							are protected by copyright and trademark laws and various other intellectual property
							rights and unfair competition laws of the United States, international copyright laws, and
							international conventions. The Content and the Marks are provided on the Site “AS IS” for
							your information and personal use only. Except as expressly provided in these Terms of
							Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated,
							republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
							distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever,
							without our express prior written permission.</p>
							<p>Provided that you are eligible to use the Site, you are granted a limited license to access
							and use the Site and to download or print a copy of any portion of the Content to which
							you have properly gained access solely for your personal, non-commercial use. We
							reserve all rights not expressly granted to you in and to the Site, the Content and the
							Marks.</p>

							<h3>USER REPRESENTATIONS</h3>
							<p>By using the Site, you represent and warrant that:
							(1) you have the legal capacity and you agree to comply with these Terms of Use; (2) you
							are not a minor in the jurisdiction in which you reside
							; (3) you will not access the Site through automated or non-human means, whether
							through a bot, script or otherwise; (4) you will not use the Site for any illegal or
							unauthorized purpose; and (5) your use of the Site will not violate any applicable law or
							regulation.</p>
							<p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we
							have the right to suspend or terminate your account and refuse any and all current or
							future use of the Site (or any portion thereof).</p>
							
							<h3>FEES AND PAYMENT</h3>
							<p>We accept the following forms of payment:</p>
							<ul>
								<li>Visa</li>
								<li>Mastercard</li>
							</ul>
							<p>You may be required to purchase or pay a fee to access some of our services. You agree
							to provide current, complete, and accurate purchase and account information for all
							purchases made via the Site. You further agree to promptly update account and payment
							information, including email address, payment method, and payment card expiration date,
							so that we can complete your transactions and contact you as needed. We bill you through
							an online billing account for purchases made via the Site. Sales tax will be added to the
							price of purchases as deemed required by us. We may change prices at any time. All
							payments shall be in Euros.</p>
							<p>You agree to pay all charges or fees at the prices then in effect for your purchases, and
							you authorize us to charge your chosen payment provider for any such amounts upon
							making your purchase.</p>
							<p>If your purchase is subject to recurring charges, then you consent to our charging your
							payment method on a recurring basis without requiring your prior approval for each
							recurring charge, until you notify us of your cancellation.
							We reserve the right to correct any errors or mistakes in pricing, even if we have already
							requested or received payment. We also reserve the right to refuse any order placed
							through the Site.</p>

							<h3>CANCELLATION</h3>
							<p>All purchases are non-refundable. You can cancel your subscription at any time
							By logging into your account or contacting us using the contact
							information provided below.
							Your cancellation will take effect at the end of the current paid term.
							If you are unsatisfied with our services, please email us at info@enismaro.com or call us at
							+393388966453.</p>

							<h3>PROHIBITED ACTIVITIES</h3>
							<p>You may not access or use the Site for any purpose other than that for which we make the
							Site available. The Site may not be used in connection with any commercial endeavors
							except those that are specifically endorsed or approved by us.
							As a user of the Site, you agree not to:</p>

							<ol>
								<li>Systematically retrieve data or other content from the Site to create or compile, directly
or indirectly, a collection, compilation, database, or directory without written permission
from us.</li>
								<li>Trick, defraud, or mislead us and other users, especially in any attempt to learn
sensitive account information such as user passwords.</li>
								<li>Circumvent, disable, or otherwise interfere with security-related features of the Site,
including features that prevent or restrict the use or copying of any Content or enforce
limitations on the use of the Site and/or the Content contained therein.</li>
								<li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
								<li>Use any information obtained from the Site in order to harass, abuse, or harm another
person.</li>
								<li>Make improper use of our support services or submit false reports of abuse or
misconduct.</li>
								<li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
								<li>Engage in unauthorized framing of or linking to the Site.</li>
								<li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
material, including excessive use of capital letters and spamming (continuous posting of
repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site
or modifies, impairs, disrupts, alters, or interferes with the use, features, functions,
operation, or maintenance of the Site.</li>
								<li>Engage in any automated use of the system, such as using scripts to send comments
or messages, or using any data mining, robots, or similar data gathering and extraction
tools.</li>
								<li>Delete the copyright or other proprietary rights notice from any Content.</li>
								<li>Attempt to impersonate another user or person or use the username of another user.</li>
								<li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a
passive or active information collection or transmission mechanism, including without
limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
other similar devices (sometimes referred to as “spyware” or “passive collection
mechanisms” or “pcms”).</li>
								<li>Interfere with, disrupt, or create an undue burden on the Site or the networks or
services connected to the Site.</li>
								<li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in
providing any portion of the Site to you.</li>
								<li>Attempt to bypass any measures of the Site designed to prevent or restrict access to
the Site, or any portion of the Site.</li>
								<li>Copy or adapt the Site’s software, including but not limited to Flash, PHP,HTML,
JavaScript, or other code.</li>
								<li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse
engineer any of the software comprising or in any way making up a part of the Site.</li>
								<li>Except as may be the result of standard search engine or Internet browser usage, use,
launch, develop, or distribute any automated system, including without limitation, any
spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or
launching any unauthorized script or other software.</li>
								<li>Use a buying agent or purchasing agent to make purchases on the Site.</li>
								<li>Make any unauthorized use of the Site, including collecting usernames and/or email
addresses of users by electronic or other means for the purpose of sending unsolicited
email, or creating user accounts by automated means or under false pretenses.</li>
								<li>Use the Site as part of any effort to compete with us or otherwise use the Site and/or
the Content for any revenue-generating endeavor or commercial enterprise.</li>
								<li>Sell or otherwise transfer your profile.</li>
								<li>Use the Site to advertise or offer to sell goods and services.</li>
							</ol>
						</div>
					</div>					
				</div>
			</main>
		</div>

		<Footer />
	</div>	
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import Base from '@/components/base';
import Parts from '@/components/parts';
export default {
	name: 'Terms',
	components: {
		...Base,
		...Parts
	},
	methods: {
		goto(state) {
			Hub.dispatch('UI Auth', {
				event: 'AuthStateChange',
				message: state,
			});
			this.$router.push('/');
		},
	},
	created(){
		console.log(this.$route);
	}
}
</script>

<style>
.wrapper_main.wrapper_legal,
.wrapper_main.none-padding.wrapper_legal {
	padding-top: 120px;
}

.legal-wrapper h1 {
	font-weight: bold;
	font-size: 36px;
	line-height: 44px;
	margin-bottom: 20px;
}

.legal-text h3 {
	line-height: 1.4;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 20px;
}

.legal-text h3:not(:first-child) {
	margin-top: 30px;
}

.legal-text p {
	margin-bottom: 15px;
}

.legal-text ul {
	list-style: disc;
	padding-left: 30px;
}

.legal-text li {
	list-style: disc;
	margin-bottom: 10px;
}
</style>